<template>
  <ErrorPage variant="404" />
</template>

<script>
import ErrorPage from "@/components/layout/ErrorPage.vue";

/**
 * Affiche la page 404.
 */
export default {
  name: "NotFound",
  components: {
    ErrorPage,
  },
};
</script>
